import { defineComponent, watch, reactive } from '@vue/composition-api';
import { useGetCompaniesQuery } from '@/generated-types/graphql.types';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    props: {
        companyName: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { emit } = context;
        const getCompaniesQueryVariables = reactive({
            company_name: props.companyName
        });
        const getCompaniesQueryOptions = reactive({
            enabled: !!props.companyName,
            fetchPolicy: 'cache-and-network'
        });
        const { result, refetch, onResult } = useGetCompaniesQuery(getCompaniesQueryVariables, getCompaniesQueryOptions);
        onResult(() => {
            emit('loaded', {
                suggestions: result?.value?.get_companies
            });
        });
        EventBus.$on('refetchResults', name => {
            getCompaniesQueryVariables.company_name = name;
            refetch(getCompaniesQueryVariables);
        });
        watch(() => props.companyName, () => {
            if (props.companyName) {
                getCompaniesQueryVariables.company_name = props.companyName;
                refetch(getCompaniesQueryVariables);
            }
        }, { immediate: true });
    }
});
