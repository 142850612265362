import Vue from 'vue';
import { defineComponent, ref, computed, reactive, watch } from '@vue/composition-api';
import { cloneDeep } from 'lodash';
import { searchAddressByCoC } from '@/shared/api-modules/vendor.api';
import FormCheckbox from '@/shared/components/form/FormCheckbox.vue';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import ResultPopup from '@/pages/vendor/onboarding/organisation-details/ResultPopup.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { debounce } from '@/util/utils';
import asyncPageMixin from '@/mixins/asyncPage';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import SaveFooter from '@/pages/profile/components/SaveFooter.vue';
import AddressProvider from '@/Account/components/AddressProvider.vue';
import AddressAutocomplete from '@/components/AddressAutocomplete.vue';
import Popover from '@/components/Popover.vue';
import OrganisationRating from '@/components/OrganisationRating.vue';
import { withAuthMutationHook } from '@/util/hooks';
import { useUpdateUserStateMutation, useUpdateOrganisationMutation, UserRoleState, RoleType, useGetPlaceGeocodeQuery } from '@/generated-types/graphql.types';
import { SearchRoutes } from '@/Search/search.const';
import OrganisationDetailsForm from '@/pages/vendor/onboarding/organisation-details/forms/OrganisationDetailsForm';
import OrganisationCreated from '@/pages/vendor/onboarding/organisation-details/OrganisationCreated.vue';
import { camelCase } from 'change-case';
import { toCamelCase } from '@/util/transform';
import EventBus from '@/shared/services/eventBus';
import i18n from '@/i18n/i18n-setup';
import { VAT_RATES } from '@/shared/const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
const updateProfile = withAuthMutationHook(useUpdateUserStateMutation);
const updateOrganisation = withAuthMutationHook(useUpdateOrganisationMutation);
const vatRates = [
    {
        label: i18n.t('onboarding.organisation_details.select_option_vat_21'),
        value: VAT_RATES.TWENTY_ONE
    },
    {
        label: i18n.t('onboarding.organisation_details.select_option_vat_9'),
        value: VAT_RATES.NINE
    },
    {
        label: i18n.t('onboarding.organisation_details.select_option_vat_none'),
        value: VAT_RATES.NONE
    }
];
export default defineComponent({
    components: {
        FormCheckbox,
        FormSelect,
        ResultPopup,
        PageLoadingBar,
        SaveFooter,
        AddressProvider,
        OrganisationCreated,
        OrganisationRating,
        AddressAutocomplete,
        Popover
    },
    mixins: [asyncPageMixin],
    props: {
        // TODO: Check this logic
        isProfilePage: {
            type: Boolean,
            default: false
        }
    },
    setup(_, context) {
        const { root } = context;
        const form = ref(new OrganisationDetailsForm());
        const formLoaded = ref({});
        const onProfilePage = computed(() => root.$route.name?.includes('profile-organisation'));
        const companiesSuggestions = ref([]);
        const address = ref('');
        const state = reactive({
            isSaving: false,
            isInformingOrgUpdated: false,
            sameAddress: true,
            sameEmail: false,
            companiesSuggestions: false,
            resultPopupVisible: false,
            organisationNameInputCompleted: true
        });
        const vatErr = ref('');
        const isContinueButtonDisabled = computed(() => !!form.value?.coc &&
            form.value?.vatRate !== null &&
            form.value?.organisationAddress &&
            form.value?.financeAddress);
        const getPlaceGeocodeQueryVariables = reactive({
            address: ''
        });
        const getPlaceGeocodeQueryOptions = reactive({
            enabled: false,
            fetchPolicy: 'network-only'
        });
        const { refetch, onResult } = useGetPlaceGeocodeQuery(getPlaceGeocodeQueryVariables, getPlaceGeocodeQueryOptions);
        const isUpdateAllowed = computed(() => {
            if (!user.value) {
                return false;
            }
            return (user.value?.role_type !== RoleType.VenueManager &&
                form.value.isUpdateAllowed);
        });
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const organisation = computed(() => globalThis.$store.getters['$_vendor/organisation']);
        const { mutate } = updateProfile({ clientId: 'legacy' });
        const { mutate: orgMutate } = updateOrganisation({ clientId: 'legacy' });
        const switchToBooker = async () => {
            await mutate({ profile: { accountState: UserRoleState.Booker } });
            globalThis.$router.push(useGetLocalizedPath(SearchRoutes.SEARCH));
        };
        const onAddressInput = debounce(async function fn() {
            address.value = form.value.name;
            state.organisationNameInputCompleted = false;
        }, 500);
        const setCompaniesSuggestions = (res) => {
            companiesSuggestions.value = res?.suggestions?.map((item, key) => ({
                ...item,
                id: key,
                label: item.legal_name + ' (' + item.coc_number + ')'
            }));
        };
        const onCoCInput = async function fn() {
            if (!form.value.coc)
                return;
            Vue.set(form.value._errors, 'coc', '');
            Vue.set(form.value._errors, 'vatNumber', '');
            try {
                const address = await searchAddressByCoC(form.value.coc);
                if (!address.address) {
                    Vue.set(form.value._errors, 'coc', root.$t('onboarding.organisation_details.errors.coc_not_found'));
                    form.value.organisationAddress = '';
                }
                else {
                    form.value.organisationAddress = address.address;
                    form.value.financeAddress = address.address;
                    form.value.vatNumber = address.org.btw;
                    // enable useGetPlaceGeocodeQuery
                    getPlaceGeocodeQueryOptions.enabled = true;
                    getPlaceGeocodeQueryVariables.address = address.address;
                    refetch();
                    onResult((result) => {
                        const placeId = result?.data?.get_geocode[0]?.place_id;
                        form.value.placeId = placeId;
                        form.value.financePlaceId = placeId;
                    });
                }
            }
            catch (e) {
                Vue.set(form.value._errors, 'coc', root.$t('onboarding.organisation_details.errors.coc_not_found'));
                form.value.organisationAddress = '';
            }
        };
        const addressAutocompleteSelected = id => {
            const company = companiesSuggestions.value.find(item => id === item.id);
            form.value.name = company.legal_name;
            form.value.coc = company.coc_number;
            companiesSuggestions.value = [];
            onCoCInput();
            state.organisationNameInputCompleted = true;
            Vue.set(form.value._errors, 'name', null);
        };
        const onRevert = () => {
            updateForm();
        };
        const organisationAddressChange = () => {
            form.value.financePlaceId = null;
        };
        const updatedOrgAddress = (val) => {
            if (val.id) {
                form.value.financePlaceId = val.id;
            }
        };
        const setFinanceEmailByCurrentUser = () => {
            if (user.value && user.value.email) {
                if (form.value.financeEmail) {
                    state.sameEmail = form.value.financeEmail === user.value.email;
                }
                else {
                    form.value.financeEmail = user.value.email;
                    state.sameEmail = true;
                }
            }
        };
        const onClose = () => {
            state.isInformingOrgUpdated = false;
        };
        const isChangesSaved = () => {
            return !isSettingsChanged() && state.isInformingOrgUpdated;
        };
        const isSettingsChanged = () => {
            if (isUpdateAllowed.value && organisation.value) {
                return JSON.stringify(form.value) !== JSON.stringify(formLoaded.value);
            }
            return false;
        };
        const updateForm = () => {
            form.value.loadFromModel(organisation.value);
            setFinanceEmailByCurrentUser();
            formLoaded.value = cloneDeep(form.value);
            if (form.value.organisationAddress) {
                if (!form.value.financeAddress) {
                    form.value.financeAddress = form.value.organisationAddress;
                    state.sameAddress = true;
                }
                else {
                    state.sameAddress =
                        form.value.financeAddress === form.value.organisationAddress;
                }
            }
        };
        const onSameEmailClick = () => {
            if (state.sameEmail) {
                form.value.financeEmail = user.value?.email;
            }
        };
        const createVenue = () => {
            globalThis.$router.push({ name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.MAIN });
        };
        const closeResultPopup = () => {
            globalThis.$router.push({ name: ROUTE_LIST_NAMES.MAIN });
        };
        // Main submit function for organisation creation
        const nextPage = async () => {
            if (!form.value.validate(root))
                return;
            state.isSaving = true;
            try {
                const organisationResponse = await globalThis.$store.dispatch('$_vendor/saveOrganisation', form.value.buildSendData());
                if (onProfilePage.value) {
                    form.value.id = organisationResponse.organisation_id;
                    form.value.organisationRating = 10;
                    formLoaded.value = cloneDeep(form.value);
                    state.isInformingOrgUpdated = true;
                }
                else {
                    state.resultPopupVisible = true;
                }
                const organisationDetailsForm = new OrganisationDetailsForm();
                organisationDetailsForm.loadFromAPI(organisationResponse);
                globalThis.$store._mutations['$_vendor/SET_ORGANISATION'][0](organisationDetailsForm);
                globalThis.$store.dispatch('$_app/refetchUser');
            }
            catch (e) {
                if (e?.data?.entity === 'ValidOrganisation') {
                    Vue.set(form.value._errors, 'name', root.$t(`common.errors.${e.data.key}`));
                }
                catchServerErr(e.data);
            }
            state.isSaving = false;
        };
        // Main submit function for organisation editing
        const onSaveChanges = () => {
            if (!form.value.id) {
                nextPage();
                return;
            }
            if (!state.organisationNameInputCompleted) {
                Vue.set(form.value._errors, 'name', '');
                form.value.name = '';
            }
            if (!form.value.validate()) {
                if (form.value._errors.financePlaceId) {
                    Vue.set(form.value._errors, 'financePlaceId', form.value._errors.financePlaceId);
                    root.$scrollTo('#financePlaceId', 1500, { offset: -100 });
                }
                return;
            }
            state.isSaving = true;
            const requestFields = form.value.buildSendData();
            delete requestFields.organisation_type;
            delete requestFields.organisation_rating;
            requestFields.is_finance_coc_geo = true;
            delete requestFields.place_id;
            const input = toCamelCase(requestFields);
            orgMutate({ input }, {})
                .then(() => {
                formLoaded.value = cloneDeep(form.value);
                state.isInformingOrgUpdated = true;
            })
                .catch(({ graphQLErrors }) => {
                catchServerErr(graphQLErrors[0].extensions.responseBody);
                state.isInformingOrgUpdated = false;
            });
        };
        const catchServerErr = e => {
            if (!e || e.entity !== 'organisation')
                return;
            const error = Object.keys(e.meta).map(el => camelCase(el));
            vatErr.value = root.$t(`onboarding.organisation_details.errors.${e.key}`);
            root.$forceUpdate(); // TODO: check if needed
            root.$scrollTo(`#${error[0]}`, 500, {
                offset: -300
            });
        };
        const setupAddress = (val) => {
            form.value._errors.financePlaceId = '';
            if (val.id) {
                form.value.financePlaceId = val.id;
                form.value.financeAddress = val.address;
            }
        };
        // Watchers
        watch(() => organisation.value, () => {
            updateForm();
        }, { immediate: true });
        watch(() => form.value.name, () => {
            if (form.value.name === '')
                companiesSuggestions.value = [];
        }, { immediate: true });
        watch(() => state.sameAddress, () => {
            if (state.sameAddress) {
                form.value.financeAddress = form.value.organisationAddress;
                form.value.financePlaceId = form.value.placeId;
            }
        }, { immediate: true });
        watch(() => form.value.vatNumber, () => {
            // Cleaning vat error on input change
            vatErr.value = '';
        }, { immediate: true });
        watch(() => user.value, () => {
            if (user.value?.email) {
                formLoaded.value.financeEmail = user.value.email;
                if (form.value.financeEmail) {
                    state.sameEmail = form.value.financeEmail === user.value.email;
                }
                else {
                    form.value.financeEmail = user.value.email;
                    state.sameEmail = true;
                }
            }
        }, { immediate: true });
        const onOrganisationNameFocusOff = () => {
            if (!state.organisationNameInputCompleted) {
                Vue.set(form.value._errors, 'name', root.$t('onboarding.organisation_details.errors.make_choice'));
            }
        };
        const onOrganisationNameFocusOn = () => {
            EventBus.$emit('refetchResults', form.value.name);
        };
        return {
            address,
            companiesSuggestions,
            form: computed(() => form.value),
            isContinueButtonDisabled,
            isUpdateAllowed,
            onAddressInput,
            onProfilePage,
            organisation,
            state,
            user,
            vatErr: computed(() => vatErr.value),
            vatRates,
            addressAutocompleteSelected,
            closeResultPopup,
            createVenue,
            isChangesSaved,
            isSettingsChanged,
            nextPage,
            onOrganisationNameFocusOff,
            onOrganisationNameFocusOn,
            onClose,
            onRevert,
            onSameEmailClick,
            onSaveChanges,
            organisationAddressChange,
            setCompaniesSuggestions,
            setupAddress,
            switchToBooker,
            updatedOrgAddress
        };
    }
});
