import { ref, defineComponent, reactive, watch, computed } from '@vue/composition-api';
import debounce from 'lodash/debounce';
import { useGetPlaceInfoQuery, useGetPlacesAutocompleteQuery, PlaceApiStatus } from '@/generated-types/graphql.types';
import i18n from '@/i18n/i18n-setup';
import { uuidv4 } from '@/util/uuid';
export default defineComponent({
    props: {
        form: {
            type: Object,
            default: () => { }
        },
        fieldName: {
            type: String,
            default: 'address'
        },
        placeIdField: {
            type: String,
            default: 'placeID'
        },
        entity: {
            type: String,
            default: 'venue',
            validator: function (value) {
                // The value must match one of these strings
                return ['venue', 'organisation'].indexOf(value) !== -1;
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const googleSessionToken = ref(undefined);
        const suggestionsCache = ref([]);
        const suggestions = ref([]);
        const address = ref(props.form[props.fieldName]);
        const placesAutocompleteQueryVariables = reactive({
            autocompleteRequest: '',
            google_session_token: '',
            language: ''
        });
        const placesAutocompleteQueryOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        /**
         * Watching current site locale changes to update the places autocomplete input
         */
        watch(() => globalThis.$i18n.locale, () => {
            placesAutocompleteQueryVariables.language = globalThis.$i18n.locale;
        }, { immediate: true });
        watch(() => props.form[props.fieldName], val => {
            if (val) {
                address.value = val;
                placesAutocompleteQueryVariables.autocompleteRequest = val;
                placesAutocompleteQueryOptions.enabled = true;
            }
        }, { immediate: true });
        const getPlaceInfoQueryVariables = reactive({
            place_id: '',
            google_session_token: ''
        });
        const getPlaceInfoQueryOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { refetch: placeInfoRefetch, onResult: placeInfoOnResult } = useGetPlaceInfoQuery(getPlaceInfoQueryVariables, getPlaceInfoQueryOptions);
        const { refetch: autocompleteRefetch } = useGetPlacesAutocompleteQuery(placesAutocompleteQueryVariables, placesAutocompleteQueryOptions);
        /**
         * On input change callback
         * Starts autocomplete re-fetch
         */
        const onAddressInput = async (val) => {
            emit('clear');
            if (!googleSessionToken.value)
                googleSessionToken.value = uuidv4();
            placesAutocompleteQueryVariables.autocompleteRequest = val;
            placesAutocompleteQueryVariables.google_session_token =
                googleSessionToken.value;
            placesAutocompleteQueryOptions.enabled = true;
            // re-fetch query only if the input is enabled
            autocompleteRefetch(placesAutocompleteQueryVariables, placesAutocompleteQueryOptions).then(res => {
                /**
                 * Autocomplete onResult callback
                 * getting predictions from api and creating suggestions list
                 */
                const response = res?.data?.places_autocomplete;
                if (response?.status === PlaceApiStatus.Ok) {
                    suggestionsCache.value = response?.predictions
                        ?.filter(prediction => {
                        const isSpacePrediction = prediction.types[0] === 'space';
                        const isVenuePrediction = prediction.types[0] === 'venue';
                        if (!isSpacePrediction && !isVenuePrediction) {
                            return prediction;
                        }
                    })
                        .map(prediction => ({
                        id: prediction.place_id,
                        label: prediction.description,
                        street: prediction.structured_formatting.main_text
                    }));
                    if (!props.fieldName)
                        props.form.dropAddress();
                    if (response?.predictions?.length === 1) {
                        suggestions.value = []; // no need for a list of suggestion
                        const placeId = response?.predictions[0]?.place_id;
                        selectAddressByPlaceId(placeId);
                    }
                    else {
                        suggestions.value =
                            !props.disabled && !props.form[props.placeIdField]
                                ? [...suggestionsCache.value]
                                : [];
                    }
                }
                else {
                    if (response?.status === PlaceApiStatus.ZeroResults) {
                        console.log('PlaceApi request error', response);
                    }
                    props.form.failAddress(root);
                }
            });
        };
        /**
         * Select address py placeID
         * Creating address form instance and
         * re-fetching place information (address) by placeID
         */
        const selectAddressByPlaceId = (id, label) => {
            suggestions.value = [];
            if (id && label) {
                emit('setAddress', {
                    id: id,
                    address: label
                });
                return;
            }
            // enable useGetPlaceInfoQuery and re-fetch it with the obtained place ID
            getPlaceInfoQueryOptions.enabled = true;
            getPlaceInfoQueryVariables.place_id = id;
            getPlaceInfoQueryVariables.google_session_token =
                googleSessionToken.value;
            placeInfoRefetch(getPlaceInfoQueryVariables, getPlaceInfoQueryOptions);
        };
        /**
         * PlaceInfo onResult callback
         * Getting place information (address) from api by placeID
         */
        placeInfoOnResult((result) => {
            const response = result?.data?.get_place_info;
            if (response?.status === PlaceApiStatus.Ok) {
                emit('setAddress', {
                    id: response?.result?.place_id,
                    address: response?.result?.formatted_address
                });
            }
        });
        const addressLabel = computed(() => props.entity === 'venue'
            ? i18n.t('onboarding.venue.details.input_label_venue_address')
            : i18n.t('onboarding.organisation_details.input_placeholder_finance_address'));
        const addressPlaceholder = computed(() => props.entity === 'venue'
            ? i18n.t('onboarding.venue.details.input_placeholder_venue_address')
            : i18n.t('onboarding.organisation_details.input_placeholder_finance_address'));
        const onBlur = () => {
            googleSessionToken.value = googleSessionToken.value = uuidv4();
        };
        return {
            addressLabel,
            addressPlaceholder,
            onAddressInput: debounce(onAddressInput, 500),
            onBlur: debounce(onBlur, 500),
            suggestions,
            selectAddressByPlaceId
        };
    }
});
